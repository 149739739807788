import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Footer, Navbar } from "../components";
import { trackProductPurchase } from "../traking";
import { useDispatch } from "react-redux";
import { delCart } from "../redux/action";

const ThankYou = () => {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("orderDetails"));
    if (orderData) {
      setOrderDetails(orderData);
      
    }
  }, []);

  if (!orderDetails) {
    return <div>Loading...</div>;
  }

  const { products, subtotal, shipping } = orderDetails;

  trackProductPurchase(Math.round(subtotal), shipping, Math.round(subtotal + shipping), products);

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Thank You for Your Order!</h1>
        <hr />
        <div className="order-summary">
          <h3>Order Summary</h3>
          <ul className="list-group">
            {products.map((product, index) => (
              <li key={index} className="list-group-item">
                <div className="d-flex justify-content-between">
                  <div>{product.title}</div>
                  <div>
                    {product.qty} x ${product.price}
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="d-flex justify-content-between mt-3">
            <div>Subtotal:</div>
            <div>${Math.round(subtotal)}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Shipping:</div>
            <div>${shipping}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Total:</div>
            <div>${Math.round(subtotal + shipping)}</div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ThankYou;
