import React from "react";

// Track a login event
export function trackLogin(customerid,email) {
    window.Unito.identify({
        //customerid: customerid,
        emailaddress: email,
       });
    window.Unito.track("Login", {
    });
    
}

// Track a registration event
export function trackRegister(name, email) {
    window.Unito.add({
        name: name,
        emailaddress: email,
   });

    window.Unito.identify({
        emailaddress: email,
    });

    window.Unito.track("Register", {
    });
    
  }

// Track a product view event
export function trackProductView(productName,price,sku,description,category,img,rating) {
    window.Unito.track("ProductView", {
      url: document.location.href,
      productName: productName,
      price: price,
      sku:sku,
      description:description,
      image: img,
      category: category,
      rating: rating
    });
    
  }


// Track add to cart event
export function trackAddToCart(productName,price,sku,description,category,img,rating) {
    window.Unito.track("AddToCart", {
        url: document.location.href,
        productName: productName,
        price: price,
        sku:sku,
        description:description,
        image: img,
        category: category,
        rating: rating
    });
    
  }

// Track cart view event
export function trackCartView(product_price,shipping,total_amount,items) {
    window.Unito.track("CartView", {
        product_price: product_price,
        shipping: shipping,
        total_amount: total_amount,
        items: items
    });
    
  }

// Track checkout event
export function trackCheckout(product_price,shipping,total_amount) {
    window.Unito.track("Checkout", {
        product_price: product_price,
        shipping: shipping,
        total_amount: total_amount,
    });
    
  }

// Track Puurchase Success event
export function trackProductPurchase(product_price,shipping,total_amount,items) {
  window.Unito.track("ProductPurchase", {
      product_price: product_price,
      shipping: shipping,
      total_amount: total_amount,
      items: items
  });
  
}

export function trackLogout() {
  window.Unito.clear();
  
}